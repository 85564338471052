@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/sizes';
@import '../../styles/responsive-breakpoints';

body {
  .p-dialog {
    max-width: 608px;
    width:100%;

    @include until($new-desktop) {
      max-width: 80%;
    }

    @include until($new-tablet) {
      max-width: 100%;
    }

    .p-dialog-visible {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .p-dialog-content {
      padding: 40px;
    }
  }
}
