/* stylelint-disable no-descending-specificity */

@import '../../styles/colors';
@import '../../styles/responsive-breakpoints';
@import '../../styles/sizes';
@import '../../styles/fonts';

.checkBoxButton {
  width: 100%;
  height: 48px;
  border-radius: 3px;
  padding-left: 12px;
  color: $color-motokiki-black;
  background-color: $color-white;
  display: flex;
  margin: 10px 10px 5px;
  border: none;
  flex: 0 1 120px;
  font-size: 10px;

  &.icon-right {
    position: relative;

    img {
      float: right;
      right: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  p {
    font-size: 1rem;
    color: $color-motokiki-black;
    font-weight: 400;
    font-family: $font-family;
    line-height: 16px;
    margin: auto 0;
  }

  .checkBox{
    margin: auto 10px auto 0;
    width: 15px;
    height: 15px;
    position: relative;
    background-color: $color-white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: $border-width solid $color-mid-grey;
    border-radius: 2px;

  }

  .checkBoxIcon {
    width: 28px;
    margin-right: 0.75rem;
  }

  &.checked {
    .checkBox{
      border: $border-width-thick solid $color-white;

      &::after {
        width: 5px;
        height: 10px;
        position: relative;
        content: '';
        background-color: transparent;
        border: solid $color-dark-grey;
        display: inline-block;
        visibility: visible;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-box-sizing: border-box;
        box-sizing: border-box
      }
    }
  }

  &.large {
    flex: 1 1 200px;
    border: $border-width solid $color-mid-grey;

    .checkBox {
      &:focus{ outline: none }
    }

    &:focus, &:hover{
      border-color: $color-dark-grey;
    }

    &.checked{
      border-color: $color-dark-grey;
      background-color: $color-dark-grey;

      p { color: $color-white}
    }

  }

  &.small{
    .checkBox{
      width: 17px;
      height: 17px;
    }

    &:focus{ outline: none }

    &:hover {
      .checkBox{
        border-color: $color-dark-grey;
      }
    }

    &.checked {
      .checkBox{
        border-color: $color-dark-grey;
        background-color: $color-dark-grey;

        &::after {
          border-color: $color-white;
        }
      }
    }
  }

  &.disabled{
    background-color: $color-disabled;
    cursor: not-allowed;

    p {color: $color-mid-grey}

    .checkBox{
      background-color: $color-disabled;
      cursor: not-allowed;
    }

    .checkBoxIcon{
      opacity: 0.5;
    }

    &:hover {
      border: $border-width solid $color-mid-grey;
    }
  }
}
