$very-tiny-mobile: 370px !default;
$tiny-mobile: 410px !default;
$small-mobile: 430px !default;
$mobile: 480px !default;
$large-mobile: 550px !default;
$small-tablet: 564px !default;
$tablet: 769px !default;
$large-tablet: 840px !default;
$very-large-tablet: 991px !default;
$desktop: 1025px !default;
$large-desktop: 1080px !default;
$widescreen: 1200px !default;
$large-widescreen: 1280px !default;
$fullhd: 1384px !default;

$new-tablet: 600px !default;
$new-desktop: 1024px !default;
$new-wide-desktop: 1200px !default;

@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content;
  }
}

@mixin very-tiny-mobile {
  @media screen and (min-width: $very-tiny-mobile), print {
    @content;
  }
}

@mixin very-tiny-mobile-only {
  @media screen and (min-width: $very-tiny-mobile) and (max-width: $tiny-mobile - 1px) {
    @content;
  }
}

@mixin tiny-mobile {
  @media screen and (min-width: $tiny-mobile), print {
    @content;
  }
}

@mixin tiny-mobile-only {
  @media screen and (min-width: $tiny-mobile) and (max-width: $small-mobile - 1px) {
    @content;
  }
}

@mixin small-mobile {
  @media screen and (min-width: $tiny-mobile), print {
    @content;
  }
}

@mixin small-mobile-only {
  @media screen and (min-width: $small-mobile) and (max-width: $mobile - 1px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (min-width: $mobile), print {
    @content;
  }
}

@mixin mobile-only {
  @media screen and (min-width: $mobile) and (max-width: $large-mobile - 1px) {
    @content;
  }
}

@mixin large-mobile {
  @media screen and (min-width: $large-mobile), print {
    @content;
  }
}

@mixin large-mobile-only {
  @media screen and (min-width: $large-mobile) and (max-width: $small-tablet - 1px) {
    @content;
  }
}

@mixin small-tablet {
  @media screen and (min-width: $small-tablet), print {
    @content;
  }
}

@mixin small-tablet-only {
  @media screen and (min-width: $small-tablet) and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet), print {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $large-tablet - 1px) {
    @content;
  }
}

@mixin large-tablet {
  @media screen and (min-width: $large-tablet), print {
    @content;
  }
}

@mixin large-tablet-only {
  @media screen and (min-width: $large-tablet) and (max-width: $very-large-tablet - 1px) {
    @content;
  }
}

@mixin very-large-tablet {
  @media screen and (min-width: $very-large-tablet), print {
    @content;
  }
}

@mixin very-large-tablet-only {
  @media screen and (min-width: $very-large-tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media screen and (min-width: $desktop) and (max-width: $large-desktop - 1px) {
    @content;
  }
}

@mixin large-desktop {
  @media screen and (min-width: $large-desktop) {
    @content;
  }
}

@mixin large-desktop-only {
  @media screen and (min-width: $large-desktop) and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: $widescreen) {
    @content;
  }
}

@mixin widescreen-only {
  @media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px) {
    @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: $widescreen) {
    @content;
  }
}

@mixin widescreen-only {
  @media screen and (min-width: $widescreen) and (max-width: $large-widescreen - 1px) {
    @content;
  }
}

@mixin large-widescreen {
  @media screen and (min-width: $large-widescreen) {
    @content;
  }
}

@mixin widescreen-only {
  @media screen and (min-width: $large-widescreen) and (max-width: $fullhd - 1px) {
    @content;
  }
}

@mixin fullhd {
  @media screen and (min-width: $fullhd) {
    @content;
  }
}
