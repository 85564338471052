/* Sizes */
$font-size-base       : 0.9rem;                     // 0.9rem   = 14.4px
$font-size-tiny       : $font-size-base - 0.25rem;  // 0.65rem  = 10.4px
$font-size-small      : $font-size-base - 0.15rem;  // 0.75rem  = 12px
$font-size-lg         : $font-size-base + 0.1rem;   // 1rem     = 16px
$font-size-very-lg    : $font-size-base + 0.25rem;  // 1.15rem  = 18.4px
$font-size-super-lg   : $font-size-base + 0.45rem;  // 1.35rem  = 21.6px
$font-size-huge       : $font-size-base + 0.6rem;   // 1.5rem   = 24px
$font-size-super-huge : $font-size-base * 2;        // 1.8rem   = 28.8px

$font-size-fs-body    : $font-size-base - 0.025rem; // 0.875rem = 14px
$font-size-fs-m       : $font-size-base + 0.35rem;  // 1.25rem  = 20px

$font-weight-normal   : 400;
$font-weight-bold     : 600;
$font-weight-heavy    : 700;

$border-width             : 1px;
$border-width-thick       : 2px;
$border-width-very-thick  : 3px;
$border-width-super-thick : 6px;

$button-height      : 40px;
$line-height-base   : 1.5;
$border-radius-base : 4px;
$border-radius-sm   : 2px;
$base-margin        : 16px;
$double-margin      : 32px;
