@import '../../styles/colors';
@import '../../styles/sizes';

.rdw-editor-wrapper {
  .toolbar {
    margin-bottom: 0;
    border: $border-width solid $color-mid-grey;
  }

  .editor {
    background: $color-white;
    border-left: $border-width solid $color-mid-grey;
    border-right: $border-width solid $color-mid-grey;
    border-bottom: $border-width solid $color-mid-grey;
    height: 300px;
    padding: 3px;
    font-size: 12px;
    overflow-y: auto;

    h1 {
      font-size: 22px;
      font-weight: bold;
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
