/* stylelint-disable */
@import '../../styles/colors';
@import '../../styles/responsive-breakpoints';
@import '../../styles/sizes';
@import '../../styles/fonts';

@mixin no-highlight() {
  outline: none;
  box-shadow: none !important;
}

.radioGroup{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -10px;

  .radioButton {
    width: 100%;
    height: 48px;
    border-radius: 3px;
    padding-left: 12px;
    color: $color-motokiki-black;
    background-color: $color-white;
    display: flex;
    margin:10px 10px 5px;
    border: none;
    flex: 0 1 120px;

    &.large {
      flex: 1 1 200px;
      border: $border-width solid $color-mid-grey;

      &:focus, &:hover{
        border-color: $color-motokiki-black;
      }
    }

    p {
      font-size: 1rem;
      color: $color-motokiki-black;
      font-weight: 400;
      font-family: $font-family;
      line-height: 16px;
      margin: auto 0;
    }

    .radio{
      margin: auto 10px auto 0;
      width: 16px;
      height: 16px;
      border-radius: 15px;
      position: relative;
      background-color: $color-white;
      content: '';
      display: flex;
      visibility: visible;
      border: $border-width solid $color-mid-grey;
    }

    .radio-icon{
      padding-left: 10px;
    }

    &.checked {
      &.large{
        background-color: $color-dark-grey;

        p { color: $color-white}
      }

      .radio{
        border: $border-width solid $color-white;

        &::after {
          width: 8px;
          height: 8px;
          border-radius: 15px;
          position: relative;
          background-color: $color-white;
          content: '';
          display: flex;
          visibility: visible;
          border: 4px solid $color-dark-grey;
          top: 3px;
          left: 3px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box
        }
      }

      &.small .radio{
        border: $border-width solid $color-dark-grey;

        &::after {
          background-color: $color-dark-grey;
        }
      }
    }

    &.small{
      &:focus{ outline: none }

      &:hover .radio{
        border-color: $color-motokiki-black;
      }
    }

    &.circle {
      min-height: unset;
      height: 35px;
      max-width: 35px;
      margin: 2px;
      border-radius: 100%;
      padding: 0;
      border: $border-width-thick solid $color-light-grey;
      background: $color-white;
      box-shadow: none;
      color: $color-motokiki-black;
      display:flex;
      align-items: center;
      justify-content: center;

      .radio {
        display: none;
      }

      p {
        margin: 0;
      }

      &.checked {
        border: $border-width-thick solid $color-motokiki-green;

        p {
          font-weight: 700;
        }
      }
    }
  }
}
