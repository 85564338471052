@import '../../styles/mixins';

.animated-tyre {
  &-image {
    display: block;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    animation: spinning-tyre 2.5s infinite linear;
    // width: 30px;
    // height: 30px;
  }
}

.xx-small {
  @include scaleLoader(30)
}

@keyframes spinning-tyre {
  100% {
    transform: rotate(360deg);
  }
}
