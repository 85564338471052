@import '../../styles/colors';
@import '../../styles/responsive-breakpoints';
@import '../../styles/sizes';
@import '../../styles/fonts';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  padding: 0.75rem 2rem;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  font-family: $font-family !important;
  border-radius: 24px;
  text-rendering: optimizeLegibility;
  text-decoration: none;
  cursor: pointer;
  background-color: $color-orange;
  color: $color-white;
  min-height: 3rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    font-family: $font-family !important;
  }


  &.small {
    padding: 0.25rem 1.5rem;
    min-height: 2.5rem;
  }

  &:hover {
    transition: 200ms ease-in;
    background-color: $color-orange-hover;
  }

  &:focus {
    outline: none;
  }

  &.secondary {
    background-color: $color-secondary;
    color: $color-motokiki-black;

    &:hover {
      background-color: $color-secondary-hover;
    }
  }

  &.primary-alternative {
    background-color: $color-success;
    color: $color-white;

    &:hover {
      background-color: $color-success-hover;
    }
  }

  &.tertiary {
    background-color: $color-white;
    color: $color-motokiki-orange;
    border: solid 1px $color-motokiki-orange;

    &:hover {
      color: $color-orange-hover;
      border: solid 1px $color-orange-hover;
      box-shadow: inset 0 0 0 1px $color-orange-hover;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      transition: 200ms ease-in;
    }
  }

  &.disabled {
    background-color: $color-disabled;
    color: $color-light-grey;
    cursor: not-allowed;

    &:hover {
      transition: none;
      background-color: none;
    }
  }

  &.ghost {
    background-color: $transparent;
    color: $color-motokiki-black;
    border: solid 1px $color-light-grey;

    &:hover {
      box-shadow: inset 0 0 0 1px $color-motokiki-black;
      border: solid 1px $color-motokiki-black;
      transition: 200ms ease-in;
    }
  }

  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.rebrand-primary {
    background-color: $color-text-red;
    color: $color-white;

    &.tertiary {
      background-color: $color-white;
      color: $color-text-red;
      border: solid 1px $color-text-red;
  
      &:hover {
        color: $color-text-red;
        border: solid 1px $color-text-red;
        box-shadow: inset 0 0 0 1px $color-text-red;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        transition: 200ms ease-in;
      }
    }
  }

  &.rebrand-secondary {
    background-color: $color-text-blue;
    color: $color-white;

    &.tertiary {
      background-color: $color-white;
      color: $color-text-blue;
      border: solid 1px $color-text-blue;
  
      &:hover {
        color: $color-text-blue;
        border: solid 1px $color-text-blue;
        box-shadow: inset 0 0 0 1px $color-text-blue;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        transition: 200ms ease-in;
      }
    }
  }
}

.loading-with-flex {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  
  p {
    margin: 0;
  }
}

.arrow-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  .right-arrow-regular {
    height: 25px;
    width: 25px;
  }

  .right-arrow-small{
    height: 15px;
    width: 15px;
  }
}

.btn-group {
  display: flex;
  width: 100%;
  align-items: center;

  .button,
  a {
    margin-right: 20px;
    width: 100%;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

body {
  .user-is-tabbing {
    *:focus {
      box-shadow: 0 0 0 4px $color-secondary-hover;
    }
  }
}

.long {
  padding: 0.813rem 1rem;
  font-size: 0.9rem;
  width: 100%;
}

@media only screen and (max-width: $mobile) {
  .btn-group {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    .button,
    a {
      margin: 7px 0;
    }
  }
}
