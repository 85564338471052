@import './fonts';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');

@font-face {
  font-family: LicencePlate;
  src: url('https://cdn2.hubspot.net/hubfs/4596719/fonts/UKNumberPlate.svg'); /* IE9 Compat Modes */
  src: url('https://cdn2.hubspot.net/hubfs/4596719/fonts/UKNumberPlate.eot#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://cdn2.hubspot.net/hubfs/4596719/fonts/UKNumberPlate.woff2') format('woff2'), /* Super Modern Browsers */
    url('https://cdn2.hubspot.net/hubfs/4596719/fonts/UKNumberPlate.woff') format('woff'), /* Pretty Modern Browsers */
    url('https://cdn2.hubspot.net/hubfs/4596719/fonts/UKNumberPlate.ttf')  format('truetype'), /* Safari, Android, iOS */
    url('https://cdn2.hubspot.net/hubfs/4596719/fonts/UKNumberPlate.svg#svgUKNumberPlate') format('svg'); /* Legacy iOS */

  font-weight: normal;
  font-style: normal;
}

/* Primereact */
@import '~primeicons/primeicons.css';
@import '~primereact/resources/primereact.min.css';
@import '~primereact/resources/themes/nova-light/theme.css';

/* RC Time Picker */
@import '~rc-time-picker/assets/index.css';

/* React Draft WYSIWYG */
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

/* Font class generator */
.fs {
  @each $fontSize, $breakpoints in $fontSizes {
    &-#{$fontSize} {
      @each $breakpoint, $properties in $breakpoints {
        @media screen and (min-width: #{$breakpoint}) {
          @each $property, $propertyValue in $properties {
            #{$property}: #{$propertyValue};
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        }
      }
    }
  }
}
