@import '../../styles/colors';
@import '../../styles/responsive-breakpoints';
@import '../../styles/sizes';

@mixin no-highlight() {
  outline: none;
  box-shadow: none !important;
}

textarea {
  border: $border-width solid $color-mid-grey !important;

  &:hover {
    border: $border-width solid $color-motokiki-black;
  }

  &:focus,
  &:active {
    border: $border-width-thick solid $color-motokiki-black;
    color: $color-motokiki-black;
  }

  &.hasError {
    border: $border-width solid $color-error !important;
  }
}

.checkmark {
  position: absolute;
  border: 2px solid $color-mid-grey;
  border-radius: 3px;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $color-white;

  &::after {
    content: '';
    position: absolute;
    display: none;
  }

  &:hover {
    border: 2px solid $color-dark-grey;
  }

  &--error {
    border-color: $color-error;
  }

  &__text {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.1rem;
  }
}

.input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: $border-width solid $color-mid-grey;
  margin-bottom: 5px;
  padding-left: 12px;
  color: $color-motokiki-black;

  &::placeholder {
    color: $color-light-grey;
  }

  &::-ms-input-placeholder {/* Internet Explorer 10-11 */
    color: $color-light-grey;
  }

  &::-webkit-input-placeholder { /* Edge */
    color: $color-light-grey;
  }

  &:hover {
    border: $border-width solid $color-motokiki-black;
  }

  &:focus,
  &:active {
    border: $border-width-thick solid $color-motokiki-black;
    color: $color-motokiki-black;
  }

  &--error {
    border: $border-width-thick solid $color-error;
    color: $color-error;

    .checkmark {
      border-color: $color-error;
    }
  }

  &-text {
    color: $color-error;
    font-size: 12px;
  }

  &:focus {
    border: 1px solid $color-motokiki-black;
    color: $color-black;
  }

  &__error-text {
    color: $color-error;
    line-height: 17px;
    font-size: 14px;
  }

  &.successInput {
    border: $border-width solid $color-success;
    color: $color-success;
  }

  &.errorInput {
    border: $border-width solid $color-orange-hover;
    color: $color-orange-hover;

    &:focus,
    &:active {
      border: $border-width-thick solid $color-orange-hover;
    }
  }

  &.hasError {
    border: $border-width solid $color-error !important;
  }

  &.disabledInput {
    border: $border-width solid $color-dark-grey;
    color: $color-dark-grey;
    background: $color-light-grey;
  }
}

.input__label__title {
  margin-bottom: 4px;
}

.input__label--text,
.input__label--tel,
.input__label--checkbox {
  width: 100%;
  margin-bottom: 0;
}

.input__label--checkbox {
  display: flex;
  min-height: 25px;
  position: relative;
  padding-left: 35px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .checkmark {
    &::after {
      top: 2px;
      left: 8px;
      width: 8px;
      height: 16px;
      border: 1px solid $color-white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $color-dark-grey;
      border: 1px solid $color-dark-grey;

      &::after {
        display: block;
      }
    }
  }
}
