
@import '../../styles/fonts';

.time-picker-group {
  display:flex;
  // margin:-10px;

  * {
    font-family: $font-family;
  }

  .to-text {
    @include getFontProperties('body-l');

    margin: 0 10px;
    line-height: 2.5rem;
  }
}
