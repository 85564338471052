@import '../../styles/colors';
@import '../../styles/responsive-breakpoints';
@import '../../styles/sizes';
@import '../../styles/fonts';

@mixin no-highlight() {
  outline: none;
  box-shadow: none !important;
}

.checkBoxGroup{
  display: flex;
  margin:-10px;
  flex-wrap: wrap;
}
