@import '../../styles/colors';
@import '../../styles/responsive-breakpoints';
@import '../../styles/sizes';
@import '../../styles/transitions';

.loading-modal {
  max-width: 608px !important;

  &__title {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
    &__motokiki {
      color: $color-text-red;
    }
    &__motoreasy {
      color: #00A88F,
    }
  }

  &__fact {
    line-height: 1.2em;
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 40px !important;
  }

  &__animated-tyre {
    &-image {
      display: block;
      margin: 0 auto 30px auto;
      padding: 0;
      box-sizing: border-box;
      animation: spinning-tyre 2.5s infinite linear;
      width: 97px;
      height: 97px;
    }
  }
}

body {
  .p-dialog {
    &.loading-modal {
      .p-dialog-visible {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    
      .p-dialog-content {
        padding: 40px;
      }
    }
  }
}

@keyframes spinning-tyre {
  100% {
    transform: rotate(360deg);
  }
}
