/* Colours */

/* Brand */
$color-motokiki-black: #282828;
$color-motokiki-orange: #d84200;
$color-motokiki-green: #5fa90e;

/* Motoreasy */
$color-motoreasy-primary: #00a88f;
$color-motoreasy-seconday: #d9534f;
$color-motoreasy-background-dark: #4a4545;
$color-motoreasy-background-light: #e0e0e0;

/* Information & validation */
$color-error: #d0021b;
$color-success: #009282;
$color-warning: #3b699e;
$color-info: #647683;

/* Buttons & links */
$color-orange: #e84e0e;
$color-secondary: #d9dee1;
$color-disabled: #eaeaea;
$color-link: #647683;
$color-focus: #87bae7;

/* Hover/ active states */
$color-orange-hover: #c33c00;
$color-secondary-hover: #cdd2d5;
$color-success-hover: #007065;

/* Greys */
$color-cool-grey: #edeef0;
$color-light-grey: #b6bbbe;
$color-mid-grey: #999;
$color-dark-grey: #4e5b64;
$shadow: #dedede;

/* Misc */
$color-black: #000;
$color-white: #fff;
$transparent: transparent;
$color-licence-plate-yellow: #fdd501;

/**** Motokiki Rebrand Colours ****/

/* Branding*/
$color-motokiki-logo-red: #9D1E4B;
$color-motokiki-logo-blue: #11244C;

/* Motokiki rebrand text color*/
$color-text-red: #A4164A;
$color-text-red-hover: #8b1643;
$color-text-blue: #14244B;
$color-text-yellow: #FFCD17;
$color-light-grey: #e6e7e8;
$color-dark-grey: #3e444e;

$color-motokiki-logo-red: #9D1E4B;
$color-motokiki-logo-blue: #11244C;

/*Shadow effects*/
$lightShadow: rgba(0,0,0,0.2)


