@import '../../styles/colors';
@import '../../styles/mixins';


.ml-loader {
  position: relative;

  @include scaleLoader(80);
  
  &.large {
    @include scaleLoader(100);
  }

  &.small {
    @include scaleLoader(60);
  }
  
  &.x-small {
    @include scaleLoader(40);
  }
  
  &.xx-small {
    @include scaleLoader(30);
  }
  
  &.tiny {
    @include scaleLoader(20);
  }
  
  div {
    animation: 1.2s opaque ease-in-out infinite both;
		
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      border-radius: 10px;
      background-color: $color-white;
    }

    &:nth-child(1) {
      transform: rotate(0);
    }
    
    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: 0.1s;
    }
    
    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: 0.2s;
    }
    
    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: 0.3s;
    }
    
    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: 0.4s;
    }
    
    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: 0.5s;
    }
    
    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: 0.6s;
    }
    
    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: 0.7s;
    }
    
    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: 0.8s;
    }
    
    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: 0.9s;
    }
    
    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: 1s;
    }
    
    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 1.1s;
    }
    
    &:nth-child(13) {
      transform: rotate(360deg);
      animation-delay: 1.2s;
    }
  }
	
  &.orange {
    div {
      &::after {
        background-color: $color-motokiki-orange;
      }
    }
  }

  &.green {
    div {
      &::after {
        background-color: $color-success;
      }
    }
  }

  &.red {
    div {
      &::after {
        background-color: $color-error;
      }
    }
  }

  &.black {
    div {
      &::after {
        background-color: $color-black;
      }
    }
  }
}

@-webkit-keyframes opaque {
  0% {
    opacity: 0.1;
  }

  40% {
    opacity: 0.1;
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}

@keyframes opaque {
  0% {
    opacity: 0.1;
  }

  40% {
    opacity: 0.1;
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}
