@mixin calc($property, $default, $expression) {
  #{$property}: $default;
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: -moz-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin scaleLoader($scaleValue) {
  width: calc(0.7px * #{$scaleValue});
  height: calc(0.7px * #{$scaleValue});

  div {
    transform-origin: calc(0.32px * #{$scaleValue})
      calc(0.32px * #{$scaleValue});
  }

  div::after {
    left: calc(0.3px * #{$scaleValue});
    width: calc(0.05px * #{$scaleValue});
    height: calc(0.18px * #{$scaleValue});
  }
}

@mixin select-day-btn {
  text-align: center;
  flex-shrink: 0;
  background-color: $color-mid-grey;
  color: $color-white;
  height: 48px;
  width: 48px;
  line-height: 48px;
  border-radius: 50%;
  margin: 20px 10px;
  font-size: 0.9em;
  &.selected {
    background-color: $color-motokiki-logo-blue;
  }

  &:hover {
    box-shadow: 1px 1px 6px 1px rgba(153, 153, 153, 0.301);
    background-color: darken($color-mid-grey, 7%);
    &.selected {
      background-color: darken($color-motokiki-logo-blue, 7%);
    }
  }
}

@mixin modal {
  height: 100%;
  width: 100%;
  overflow: auto;
  position: fixed;
  z-index: 100;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal {
    position: relative;
    background-color: $color-white;
    padding: 15px;
    margin: auto;
    border: 1px solid $color-mid-grey;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    @-webkit-keyframes animatetop {
      from {
        top: -300px;
        opacity: 0;
      }
      to {
        top: 0;
        opacity: 1;
      }
    }

    @keyframes animatetop {
      from {
        top: -300px;
        opacity: 0;
      }
      to {
        top: 0;
        opacity: 1;
      }
    }
  }
}
