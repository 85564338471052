@import '../../styles/fonts';
@import '../../styles/sizes';
@import '../../styles/colors';
@import '../../styles/responsive-breakpoints';
@import '../../styles/shadows';
@import '../../styles/transitions';

*{font-family: $font-family}

.TabbedTableContainer{
  .tabContent{
    padding-bottom: 50px;
    max-width: 600px;
    margin: auto;

    .tab{
      transition: visibility 0s, opacity 0.8s linear;

      &.hideTab{
        visibility: hidden;
        opacity: 0;
        height: 0;
      }

      .tableHead {
        display: flex;
        width:100%;
        border-bottom: 1px solid $color-text-red;

        h2 {
          width: 50%;
          text-align: left;
          font-weight: 700;
          font-size: 16px;
        }
      }

      .TableBody{
        @include getFontProperties('body');

        display: flex;
        width:100%;
        flex-wrap: wrap;

        .row{
          border-bottom: 1px solid $color-light-grey;
          display: flex;
          height: 60px;
          width:100%;

          .TimeSlot{
            width: 50%;
            height: auto;
            margin: auto;
          }

          .SlotToggler{
            width: 50%;
            height: auto;
            margin: 0 auto;
          }
        }
      }
    }
  }

  .tabList{
    margin: 0 0 16px 0;
    border-bottom: 1px solid $color-light-grey;
    outline: none;
    -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    .tabNavContainer{
      position: relative;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin-bottom: -1px;
      overflow: hidden;
      font-size: $font-size-lg;
      line-height: 1.5;
      white-space: nowrap;
      -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      zoom: 1;

      .tabPanelScrollContainer{
        margin-bottom: -1px;
        overflow: hidden;

        .tabPanelScroll {
          overflow: hidden;
          white-space: nowrap;

          .tabPanel{
            width:100%;
            position: relative;
            display: inline-block;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            margin: 0;
            padding-left: 0;
            list-style: none;
            -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            overflow: auto;
            
            div{
              .tab{
                position: relative;
                display: inline-block;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                height: 100%;
                padding: 12px 20px;
                text-decoration: none;
                cursor: pointer;
                -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                width: 130px;
                text-align: center;
                
                &:focus{
                  outline: none
                }
              }
            }

            .animatedBar{
              -webkit-transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              background-color: $color-text-red;
              height: 4px;
              position: absolute;
              bottom: 1px;
              left: 0;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}
