@import '../../../styles/colors.scss';

.inputNumberContainer {
  display: flex;
  justify-content: space-between;
  max-width: 120px;
  padding-top: 12px;

  button {
    height:32px;
    width:32px;
    min-height:32px;
    background: $color-motokiki-orange;
    border: none;
    margin: 0 .5rem 0 0;
    padding: 0;
    outline: none;
    border-radius: 16px;
    display: flex;
    justify-content: center;

    img {
      height: 18px;
      width:18px;
    }
  }

  &.show {
    max-width: 160px
  }

  &.show, &:hover, &:focus-within{
    button {
      display:flex
    }
  }
}
