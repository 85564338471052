@import '../../styles/fonts';

.rc-time-picker {
  font-family: $font-family;
  
  .rc-time-picker-input {
    @include getFontProperties('body');

    height: 2.5rem;
    position: relative;
  }

  .time-picker-icon {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    max-width: 20px;
    max-height: 20px;
    pointer-events: none;
  }
}

.rc-time-picker-panel {
  margin-top: 1rem;

  .rc-time-picker-panel-input {
    @include getFontProperties('body');

    height: 1.66rem;
    position: relative;
  }

  .rc-time-picker-panel-select {
    width: 50%;
  }
}
