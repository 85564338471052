/* Font variables */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

$font-family-Montserrat: 'Montserrat';
$font-family: 'Open Sans';
$licence-plate-font-family: LicencePlate;

/* Font Sizes */

$fontSizes: (
  xl: (
    0px: (
      font-size: 1.5rem,
      font-weight: 700,
      line-height: 1.4
    ),
    600px: (
      font-size: 1.75rem,
      font-weight: 700,
      line-height: 1.4
    ),
    1024px: (
      font-size: 2.25rem,
      font-weight: 700,
      line-height: 1.4
    ),
    1200px: (
      font-size: 2.25rem,
      font-weight: 700,
      line-height: 1.4
    )
  ),
  l: (
    0px: (
      font-size: 1.375rem,
      font-weight: 700,
      line-height: 1.4
    ),
    600px: (
      font-size: 1.5rem,
      font-weight: 700,
      line-height: 1.4
    ),
    1024px: (
      font-size: 1.75rem,
      font-weight: 700,
      line-height: 1.4
    ),
    1200px: (
      font-size: 1.75rem,
      font-weight: 700,
      line-height: 1.4
    )
  ),
  m: (
    0px: (
      font-size: 1.25rem,
      font-weight: 700,
      line-height: 1.4
    ),
    600px: (
      font-size: 1.375rem,
      font-weight: 700,
      line-height: 1.4
    ),
    1024px: (
      font-size: 1.5rem,
      font-weight: 700,
      line-height: 1.4
    ),
    1200px: (
      font-size: 1.5rem,
      font-weight: 700,
      line-height: 1.4
    )
  ),
  s: (
    0px: (
      font-size: 1rem,
      font-weight: 700,
      line-height: 1.4
    ),
    600px: (
      font-size: 1.125rem,
      font-weight: 700,
      line-height: 1.4
    ),
    1024px: (
      font-size: 1.25rem,
      font-weight: 700,
      line-height: 1.4
    ),
    1200px: (
      font-size: 1.25rem,
      font-weight: 700,
      line-height: 1.4
    )
  ),
  footer-s: (
    0px: (
      font-size: 0.5rem,
      font-weight: 500,
      line-height: 1.4
    ),
    600px: (
      font-size: 0.6875rem,
      font-weight: 500,
      line-height: 1.4
    ),
    1024px: (
      font-size: 	0.6875rem,
      font-weight: 500,
      line-height: 1.4
    ),
    1200px: (
      font-size: 	0.6875rem,
      font-weight: 500,
      line-height: 1.4
    )
  ),
  footer-s-bold: (
    0px: (
      font-size: 0.5rem,
      font-weight: 700,
      line-height: 1.4
    ),
    600px: (
      font-size: 0.6875rem,
      font-weight: 700,
      line-height: 1.4
    ),
    1024px: (
      font-size: 	0.6875rem,
      font-weight: 700,
      line-height: 1.4
    ),
    1200px: (
      font-size: 	0.6875rem,
      font-weight: 700,
      line-height: 1.4
    )
  ),
  footer-xs: (
    0px: (
      font-size: 0.5rem,
      font-weight: 700,
      line-height: 1.4
    ),
    600px: (
      font-size: 0.6875rem,
      font-weight: 700,
      line-height: 1.4
    ),
    1024px: (
      font-size: 	0.6875rem,
      font-weight: 700,
      line-height: 1.4
    ),
    1200px: (
      font-size: 	0.6875rem,
      font-weight: 700,
      line-height: 1.4
    )
  ),
  body: (
    0px: (
      font-size: 0.875rem,
      font-weight: 400,
      line-height: 1.6
    ),
    600px: (
      font-size: 0.875rem,
      font-weight: 400,
      line-height: 1.6
    ),
    1024px: (
      font-size: 1rem,
      font-weight: 400,
      line-height: 1.6
    ),
    1200px: (
      font-size: 1rem,
      font-weight: 400,
      line-height: 1.6
    )
  ),
  body-l-bold: (
    0px: (
      font-size: 0.875rem,
      font-weight: 700,
      line-height: 1.6
    ),
    600px: (
      font-size: 1rem,
      font-weight: 700,
      line-height: 1.6
    ),
    1024px: (
      font-size: 1.125rem,
      font-weight: 700,
      line-height: 1.6
    ),
    1200px: (
      font-size: 1.125rem,
      font-weight: 700,
      line-height: 1.6
    )
  ),
  body-bold: (
    0px: (
      font-size: 0.875rem,
      font-weight: 700,
      line-height: 1.6
    ),
    600px: (
      font-size: 0.875rem,
      font-weight: 700,
      line-height: 1.6
    ),
    1024px: (
      font-size: 1rem,
      font-weight: 700,
      line-height: 1.6
    ),
    1200px: (
      font-size: 1rem,
      font-weight: 700,
      line-height: 1.6
    )
  ),
  body-xs: (
    0px: (
      font-size: 0.75rem,
      font-weight: 400,
      line-height: 1.6
    ),
    600px: (
      font-size: 0.75rem,
      font-weight: 400,
      line-height: 1.6
    ),
    1024px: (
      font-size: 0.875rem,
      font-weight: 400,
      line-height: 1.6
    ),
    1200px: (
      font-size: 0.875rem,
      font-weight: 400,
      line-height: 1.6
    )
  ),
  body-xs-bold: (
    0px: (
      font-size: 0.75rem,
      font-weight: 700,
      line-height: 1.6
    ),
    600px: (
      font-size: 0.75rem,
      font-weight: 700,
      line-height: 1.6
    ),
    1024px: (
      font-size: 0.875rem,
      font-weight: 700,
      line-height: 1.6
    ),
    1200px: (
      font-size: 0.875rem,
      font-weight: 700,
      line-height: 1.6
    )
  ),
  header: (
    0px: (
      font-size: 1.125rem,
      font-weight: 500,
      line-height: 1.6
    )
  ),
  header-bold: (
    0px: (
      font-size: 1.125rem,
      font-weight: 600,
      line-height: 1.6
    )
  ),
  header-s: (
    0px: (
      font-size: 1rem,
      font-weight: 500,
      line-height: 1.6
    )
  )
);

@mixin getFontProperties($fontSizeName) {
  @each $breakpoint, $properties in map-get($fontSizes, $fontSizeName) {
    @media screen and (min-width: #{$breakpoint}) {
      @each $property, $propertyValue in $properties {
        #{$property}: #{$propertyValue} !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
}
